import { Model } from '@vuex-orm/core'

export default class SupportTicket extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'support_ticket'

    static state() {
        return {
            loading: false,
            current_ticket: null,
            from: null,
            last_page: null,
            path: '/api/my/intercom/tickets/',
            per_page: null,
            to: null,
            total: null,
            first: null,
            last: null,
            prev: null,
            next: null,
            filter_keys: [],
            availableFilters: null,
            notice: {
                text: '',
                visible: false,
                color: 'error'
            }
        }
    }

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.uid(),
            created_at: this.attr(''),
            updated_at: this.attr(''),
            subject: this.attr(''),
            description: this.attr(''),
            status: this.attr(''),
            comments: this.attr([]),
            tags: this.attr([])
        }
    }
}
