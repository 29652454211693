import Application from '@/store/Models/Application'
export default {

    // From MyLeads
    getApplications(agent_code, filters, options) {
        Application.commit((state) => {
            state.loading = true
        })
        var params = {
            ...options,
            ...filters
        }
        this.cancelQRequest("getApplicationsQ");
        return this.getRequest('/api/private/applications/' + agent_code, params, "getApplicationsQ").then(function(json) {
            if (typeof json.meta == 'undefined') {
                return json;
            }
            Application.commit((state) => {
                state.loading = false
                state.total = json.meta.total
                if (json.meta.filters) {
                    state.availableFilters = json.meta.filters
                }
            })
            return json;
        })
    },
}
