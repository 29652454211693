//import Lead from '@/store/Models/Lead'
//import MyLeadAPI from '@/store/API/MyLead'
import QFilters from '@/components/utils/QFilters.vue'
const { Parser } = require('json2csv');

export default {
    //override this.
    props: {
        'title': {
            type: String,
            default: 'Data Table'
        },
        'rows': {
            type: Number,
            default: 5
        },
        'moreLink': {
            type: String,
            default: null
        }
    },
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'lead', //override this...
            selected: [],
            dialog: false,
            sync_dialog: false,
            search: null,
            selectAll: false,
            currentItems: [],
            filters: {},
            showFilters: false,
            options: {},
        }
    },
    computed: {
        filter_keys: function() {
            //ovveride this.
            //return Lead.state().filter_keys;
        },
        all_data: function() {
            //override this
            //return Lead.all()
        },
        the_headers: function() {
            //override this to return your headers.
            return [{
                    text: 'Name',
                    value: 'name_address',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    divider: true,
                    class: 'header-class',
                },
                {
                    text: 'Disposition',
                    value: 'Disposition',
                    sortable: true,
                    divider: true,
                    filterable: true
                }, {
                    text: 'Email',
                    value: 'Email',
                    sortable: true,
                    filterable: true,
                    width: 85
                }, {
                    text: 'County',
                    value: 'County',
                    sortable: true,
                    filterable: true
                }, {
                    text: 'Type',
                    value: 'Type',
                    sortable: true,
                    filterable: true,
                    bottom_filter: true,
                }, {
                    text: 'Lead Level',
                    value: 'LeadLevel',
                    divider: true,
                    sortable: true,
                    filterable: true
                }, {
                    text: 'Workflow',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
        },
        loading: function() {
            return this.$store.state.entities[this.entity].loading;
        },
        total_items: function() {
            return this.$store.state.entities[this.entity].total;
        },
        available_filters: function() {
            return this.$store.state.entities[this.entity].availableFilters;
        },
        computed_rows: {
            get() {
                return this.rows
            },
            set(val) {
                this.$emit('update:rows', val)
            }
        },
    },
    methods: {
        updateCurrentItems: function(items) {
            this.currentItems = items;
        },
        toggleFilters: function() {
            this.showFilters = !this.showFilters
        },
        loadData: function(opt) {
            //override this to load your data
            //MyLeadAPI.getMyLeads(this.filters, opt);
        },
        refreshData: function(refreshFilters = false) {
            var extra = {
                needAvailableFilters: !!refreshFilters || this.available_filters === null
            }
            var opt = {
                ...this.options,
                search: this.search,
                ...extra
            }
            this.loadData(opt);
        },
        maybeSearch: function(event) {
            if (event.keyCode === 13) {
                this.refreshData();
            }
        },
        formatPhoneNumber: function(phoneNumberString) {
            var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
            var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3]
            }
            return ""
        },
        startDownload: function(json, fields) {
            var opts = { fields };
            try {
                var parser = new Parser(opts);
                var csv = parser.parse(json);
                var fileURL = window.URL.createObjectURL(new Blob([csv]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                var fileName = this.slugify(this.title + " " + this.endDate);
                fileLink.setAttribute('download', fileName + ".csv");
                document.body.appendChild(fileLink);
                fileLink.click();
            } catch (err) {
                console.error(err);
            }
        },
        clicked: function(href, newTab = false) {
            if (href === undefined || href === null) {
                return;
            }

            if (href.startsWith('http') || href.startsWith('https')) {
                if (href.indexOf('quility.com') > -1 && !newTab) {
                    document.location = href;
                } else {
                    window.open(href, '_tab');
                }
            } else {
                this.$router.push(href);
            }
        },
        filterProductsByAgentDivision(products) {
            const g = this
            //show all to staff
            var user_division;
            if (this.hasRole(['Staff', 'Exec', 'SuperAdmin'])) {
                //for staff only show the division they have selected to preview.
                user_division = this.user.all_divisions.find(division => {
                    return division.Division == this.divisions[0]
                })
            } else {
                // Find Division by the Division name assigned to this user
                user_division = this.user.all_divisions.find(division => {
                    return division.Division == this.user.Agent.Division
                })
            }

            //filter carriers by its division settings
            return products.filter(product => {
                // a place to store division and its parent division settings (asusming only two levels of parent/child divisions)
                let divisionIndicators = {
                    divisionActive: "0",
                    divisionHidden: "0",
                    parentActive: "0",
                    parentHidden: "0",
                    carrierActive: "0",
                    carrierHidden: "0",
                    carrierParentActive: "0",
                    carrierParentHidden: "0"
                }

                //check the product's carrier divisions
                product.carrier.divisions.forEach(division => {
                    if (division.DivisionID == user_division.ID) {
                        divisionIndicators.carrierActive = division.ActiveInd
                        divisionIndicators.carrierHidden = division.HiddenInd
                    } else if (division.DivisionID == user_division.ParentDivisionID) {
                        divisionIndicators.carrierParentActive = division.ActiveInd
                        divisionIndicators.carrierParentHidden = division.HiddenInd
                    }
                })

                // return false if carrier parent division (All) or current division is hidden
                if (divisionIndicators.carrierParentHidden == "1" || divisionIndicators.carrierHidden == "1")
                    return false

                //check the product divisions
                product.divisions.forEach(division => {
                    if (division.DivisionID == user_division.ID) {
                        divisionIndicators.divisionActive = division.ActiveInd
                        divisionIndicators.divisionHidden = division.HiddenInd
                    } else if (division.DivisionID == user_division.ParentDivisionID) {
                        divisionIndicators.parentActive = division.ActiveInd
                        divisionIndicators.parentHidden = division.HiddenInd
                    }
                })

                if (divisionIndicators.divisionActive == "1" && divisionIndicators.divisionHidden != "1" && divisionIndicators.parentHidden != "1")
                    return true

                if (divisionIndicators.parentActive == "1" && divisionIndicators.parentHidden != "1")
                    return true

                return false
            })
        }
    },
    watch: {
        'selectAll': function(newV) {
            if (newV) {
                this.selected = this.currentItems;
            } else {
                this.selected = [];
            }
        },
        'showFilters': function(newV) {
            this.$refs.filters.show = newV
        },
        'search': function(newV) {
            this.options.page = 1
        },
        'filters': {
            deep: true,
            handler(newV) {
                var g = this;
                this.options.page = 1
                this.$nextTick(g.refreshData)
            }
        },
        'options': {
            deep: true,
            handler(newV) {
                var g = this;
                if (!this.firstPull) {
                    this.$vuetify.goTo('#' + this.entity + '_datatable', {
                        duration: 250,
                        easing: 'easeOutCubic',
                    });
                }
                this.$nextTick(g.refreshData)
            }
        },
    },
    components: {
        QFilters
    }
}
